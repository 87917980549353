import { Injectable } from '@angular/core';
import {
  CoventoCustomerFilterInput,
  CustomerFilterInput,
  CustomerRequestsGQL,
  CustomersGQL
} from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private customersGQL: CustomersGQL,
    private customerRequestsGQL: CustomerRequestsGQL
  ) { }

  getCustomers(where?: CoventoCustomerFilterInput) {
    return this.customersGQL.watch({
      where
    }).valueChanges;
  }

  getCustomerRequests() {
    return this.customerRequestsGQL.watch().valueChanges;
  }
}
